import React from "react";
import { Route, Routes } from "react-router";
import LandingPage from "./landing page";
import { WebPaths } from "../../routes/data";
import Layout from "./layout";
import Eligibility from "./eligibility";
import Auth from "./auth";
import Join from "./auth/components/SignUp";
import CheckEligibility from "./checkEligibilty";
import PROFILE from "./profile";
import FormCategoryPage from "./components/FormCategoryPage";
import About from "./pages/About";
import Help from "./pages/Help";
import Services from "./pages/Services";
import GuaranteePolicy from "./pages/GuaranteePolicy";
import Blogs from "./blogs";
import SingleNews from "./blogs/components/SingleNews";
import Feedback from "./feedback";
import FibiAi from "./landing page/components/FibiAi";
import FiBiVoice from "./landing page/components/FiBiVoice";
import BusinessService from "./pages/For Business";
import AiPolicy from "./pages/AiPolicy";

const WebRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path={WebPaths.Root} element={<LandingPage />} />
        <Route path={WebPaths.About} element={<About />} />
        <Route path={WebPaths.Businesses} element={<BusinessService />} />
        <Route path={WebPaths.Help} element={<Help />} />
        <Route path={WebPaths.Services} element={<Services />} />
        <Route path={WebPaths.Blogs} element={<Blogs />} />
        <Route path={WebPaths.FibiAi} element={<FibiAi />} />
        <Route path={WebPaths.FiBiVoice} element={<FiBiVoice />} />
        <Route path={WebPaths.GuaranteePolicy} element={<GuaranteePolicy />} />
        <Route path={WebPaths.AiPolicy} element={<AiPolicy />} />
        <Route path={`${WebPaths.Blogs}/:newsId`} element={<SingleNews />} />
        <Route path={WebPaths.Feedback} element={<Feedback />} />
        <Route
          path={`${WebPaths.Eligibility}/:formType`}
          element={<Eligibility />}
        />
        <Route
          path={`${WebPaths.checkEligibility}/:formType`}
          element={<CheckEligibility />}
        />
        <Route
          path={`${WebPaths.FormCategory}/:formCategory`}
          element={<FormCategoryPage />}
        />
        <Route path={WebPaths.Auth} element={<Auth />} />
        <Route path={WebPaths.Join} element={<Join />} />
        <Route path={WebPaths.Profile} element={<PROFILE />}></Route>
      </Route>
    </Routes>
  );
};

export default WebRoutes;
