export const WebPaths = {
  Root: "/",
  Auth: "/auth/signin",
  Join: "/auth/register",
  FormCategory: `/form`,
  Eligibility: "/eligibility",
  checkEligibility: "/check-eligibility",
  Profile: "/profile",
  About: "/about-us",
  Help: "/help",
  Services: "/services",
  Blogs: "/blogs",
  GuaranteePolicy: "/guarantee-policy",
  AiPolicy: "/ai-policy",
  Feedback: "/customer-feedback",
  FibiAi: "/fibi-ai",
  FiBiVoice: "/fibi-interviewer-voice",
  Businesses: "for-businesses",
};
